<template>
	<div id="assess">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>教学</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">教师考核</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="content">
				<div class="u-f-item u-f-jsb" style="padding-bottom: 15px;">
					<div>共{{ total }}条数据</div>
					<el-button size="small" @click="add">添加考核</el-button>
				</div>
				<el-table :data="assessList" height="88%" style="width: 100%;border: 1px solid #EEEEEE;">
					<el-table-column label="序号" type="index" width="80" align="center"></el-table-column>
					<el-table-column prop="teacher.nickname" label="教师姓名" align="center"></el-table-column>
					<el-table-column prop="semester.name" label="学期" align="center"></el-table-column>
					<el-table-column prop="class_hour_num" label="课时量" align="center"></el-table-column>
					<el-table-column prop="teaching_plan" label="教案" align="center"></el-table-column>
					<el-table-column prop="teaching_quality" label="教学质量" align="center"></el-table-column>
					<el-table-column align="center" label="操作" width="150">
						<template slot-scope="scope">
							<el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
							<el-button @click="del(scope.row.id)" type="text" size="small" style="color: red;">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="u-f-item u-f-right" style="padding: 15px 0;">
					<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page.sync="page"
						:page-size="limit"
						layout="total, prev, pager, next"
						:total="total"
					></el-pagination>
				</div>
			</div>
			
			
			<el-dialog :title="showTitle" :visible.sync="addShow" width="500px" :before-close="handleClose">
				<!--选择 -->
				<div class="selectShow">
					<el-dialog custom-class="selectShow" :visible.sync="selectShow" width="800px" :before-close="handleClose1" append-to-body>
						<div slot="title" class="u-f-item dialog-title"><div style="margin-right: 10px;">选择联系人</div></div>
						<div class="user-list-box">
							<div class="u-f-item u-f-jsb user-list-head">
								<div class="user-type-list u-f-item">
									<div
										class="user-type-item u-f-justify"
										@click="typeTap(index)"
										:class="typeIndex == index ? 'active' : ''"
										v-for="(item, index) in teacher_list"
										:key="index"
									>
										{{ item.name }}
										<span v-if="index + 1 < teacher_list.length">|</span>
									</div>
								</div>
								<!-- <el-input placeholder="请输入内容" prefix-icon="el-icon-search" style="width: 200px;" size="small" v-model="key" @change="searchTeacher"></el-input> -->
							</div>
							<div class="user-list-content u-f">
								<div class="u-f2 left" v-if="teacher_list.length">
									<div
										class="left-item"
										@click="dataTap(index)"
										:class="dataIndex == index ? 'active' : ''"
										v-for="(item, index) in teacher_list[typeIndex].data"
										:key="index"
									>
										{{ item.name }}
									</div>
								</div>
								<div class="u-f4 right" v-if="teacher_list.length">
									<div class="list" v-if="teacher_list[typeIndex].data[dataIndex]">
										<div
											class="item"
											@click="teachTap(item)"
											:class="item.checked ? 'active' : ''"
											v-for="(item, index) in teacher_list[typeIndex].data[dataIndex].teacher"
										>
											{{ item.username }}
										</div>
									</div>
								</div>
							</div>
						</div>
						<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
							<el-button @click="reset" size="small">重置</el-button>
							<div class="u-f-item">
								<!-- <span style="color: #A4A4A4;margin-right: 10px;">{{ teachers.length }}/{{ nums.length }}</span> -->
								<el-button type="primary" size="small" @click="selectShow = false">确 定</el-button>
							</div>
						</span>
					</el-dialog>
				</div>
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							学期
						</div>
						<el-select v-model="semester_id" filterable placeholder="请选择" style="width: 75%;">
							<el-option v-for="item in semesterList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal" style="padding-top: 10px;">
							<span>*</span>
							考核老师
						</div>
						<div class="u-f" style="width: 75%;">
							<el-select v-model="teacher_id"  popper-class="el_option" style="width:90%;">
								<el-option style="display: none;" v-for="(item, index) in selectList" :key="index" :label="item.username" :value="item.teacher_id"></el-option>
							</el-select>
							<el-button style="margin-left: 10px;height: 40px;" @click="selectShow = true">选择</el-button>
						</div>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal" style="padding-top: 10px;">
							<span>*</span>
							课时量
						</div>
						<el-input v-model="class_hour_num" placeholder="请输入课时量" style="width: 75%;"></el-input>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal" style="padding-top: 10px;">
							<span>*</span>
							教案
						</div>
						<el-input v-model="teaching_plan" placeholder="请输入教案" style="width: 75%;"></el-input>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal" style="padding-top: 10px;"><span>*</span>教学质量</div>
						<el-input type="textarea" :rows="5" v-model="teaching_quality" placeholder="请输入教学质量" style="width: 75%;"></el-input>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeShow" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submit">确 定</el-button>
				</span>
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			total:0,
			assessList:[],
			page:1,
			limit:10,
			addShow:false,
			teacher_list: [],
			key: '',
			typeIndex: 0,
			dataIndex: 0,
			teachers: [],
			nums: [],
			selectShow: false,
			selectList: [],
			isBnt:false,
			showTitle:'添加考核',
			semester_id:'',
			teacher_id:'',
			class_hour_num:'',
			teaching_plan:'',
			teaching_quality:'',
			semesterList:[],
			yearsList:[],
			year_id:'',
			submitType:1,
			editId:''
		};
	},
	mounted() {
		this.getXueQiList();
		this.teacherList();
		this.getteacherAssessList()
	},
	methods: {
		init(){
			this.submitType = 1;
			this.editId = '';
			this.semester_id = '';
			this.teacher_id = '';
			this.class_hour_num = '';
			this.teaching_plan = '';
			this.teaching_quality = '';
		},
		add(){
			this.submitType = 1;
			this.addShow = true;
		},
		edit(item){
			this.submitType = 2;
			this.editId = item.id;
			this.semester_id = item.semester_id;
			this.teacher_id = item.teacher_id;
			this.class_hour_num = item.class_hour_num;
			this.teaching_plan = item.teaching_plan;
			this.teaching_quality = item.teaching_quality;
			this.addShow = true;
		},
		yearChange(){
			this.getXueQiList()
		},
		handleClose1(done){
			this.typeIndex = 0;
			this.dataIndex = 0;
			done()
		},
		// 重置
		reset() {
			this.teacher_id = '';
			this.teacherList();
		},
		// 选择老师
		teachTap(item) {
			for (let i in this.teacher_list) {
				for (let j in this.teacher_list[i].data) {
					for (let t in this.teacher_list[i].data[j].teacher) {
						if (this.teacher_list[i].data[j].teacher[t].teacher_id == item.teacher_id && !this.teacher_list[i].data[j].teacher[t].checked) {
							this.teacher_list[i].data[j].teacher[t].checked = true;
						} else {
							this.teacher_list[i].data[j].teacher[t].checked = false;
						}
					}
				}
			}
			this.teacher_id = item.teacher_id;
		},
		unique(arr) {
			// 根据唯一标识orderId来对数组进行过滤
			const res = new Map(); //定义常量 res,值为一个Map对象实例 //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
			return arr.filter(arr => !res.has(arr) && res.set(arr, 1));
		},
		unique2(arr) {
			// 根据唯一标识orderId来对数组进行过滤
			const res = new Map(); //定义常量 res,值为一个Map对象实例 //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
			return arr.filter(arr => !res.has(arr.teacher_id) && res.set(arr.teacher_id, 1));
		},
		twacherTap(index) {
			this.MeunIndex = index;
		},
		// 切换分类
		typeTap(index) {
			this.typeIndex = index;
			this.dataIndex = 0;
		},
		dataTap(index) {
			this.dataIndex = index;
		},
		// 教师列表
		teacherList() {
			this.$api.pepople.teacherList({}).then(res => {
				if (res.data.code == 1) {
					this.teacher_list = res.data.data;
					for (let i in this.teacher_list) {
						for (let j in this.teacher_list[i].data) {
							for (let t in this.teacher_list[i].data[j].teacher) {
								this.nums = this.nums.concat(this.teacher_list[i].data[j].teacher[t].teacher_id);
								this.selectList = this.selectList.concat(this.teacher_list[i].data[j].teacher[t]);
							}
						}
					}
					this.nums = this.unique(this.nums);
					this.selectList = this.unique2(this.selectList);
				}
			});
		},
		// 获取学期列表
		getXueQiList(){
			this.$api.setting.getXueQiList({}).then(res=>{
				if(res.data.code==1){
					this.semesterList = res.data.data;
				}
			})
		},
		handleSizeChange(e){
			
		},
		handleCurrentChange(e){
			this.page = e;
			this.getteacherAssessList()
		},
		handleClose(done){
			this.init()
			done()
		},
		closeShow(){
			this.init()
			this.addShow = false;
		},
		submit(){
			if(this.submitType==1){
				this.addteacherAssess()
			}else{
				this.editteacherAssess()
			}
		},
		// 获取考核列表
		getteacherAssessList(){
			this.$api.teachers.teacherAssessList({
				page:this.page,
				limit:this.limit
			}).then(res=>{
				if(res.data.code==1){
					this.total = res.data.data.total;
					this.assessList = res.data.data.rows;
				}
			})
		},
		// 添加考核
		addteacherAssess(){
			this.$api.teachers.addteacherAssess({
				semester_id:this.semester_id,
				teacher_id:this.teacher_id,
				class_hour_num:this.class_hour_num, 	
				teaching_plan:this.teaching_plan, 	
				teaching_quality:this.teaching_quality
			}).then(res=>{
				if(res.data.code==1){
					this.$message.success('添加成功')
					this.init()
					this.getteacherAssessList()
					this.addShow = false;
				}else{
					this.$message.error(res.data.msg)
				}
			})
		},
		// 编辑考核
		editteacherAssess(){
			this.$api.teachers.editteacherAssess({
				semester_id:this.semester_id,
				teacher_id:this.teacher_id,
				class_hour_num:this.class_hour_num, 	
				teaching_plan:this.teaching_plan, 	
				teaching_quality:this.teaching_quality,
				id:this.editId
			}).then(res=>{
				if(res.data.code==1){
					this.$message.success('编辑成功')
					this.init()
					this.getteacherAssessList()
					this.addShow = false;
				}else{
					this.$message.error(res.data.msg)
				}
			})
		},
		// 删除考核
		del(id){
			this.$confirm('确定要删除？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(() => {
				this.$api.teachers
					.delteacherAssess({
						id: id
					})
					.then(res => {
						if (res.data.code == 1) {
							this.$message.success('删除成功');
							this.getteacherAssessList();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			});
		}
	}
};
</script>

<style lang="scss">
	#assess{
		.content{
			background-color: #ffffff;
			height: 80vh;
			margin-top: 20px;
			padding:15px 20px;
			.el-table {
				table-layout: fixed;
			}
			.el-table th > .cell,
			.el-table .cell {
				overflow: hidden; /*超出部分隐藏*/
				white-space: nowrap; /*不换行*/
				text-overflow: ellipsis; /*超出部分文字以...显示*/
			}
			.el-table thead {
				background: #fafafa !important;
			}
			.el-table th {
				padding: 15px 5px !important;
				background: #fafafa !important;
			}
			.el-table::before {
				left: 0;
				bottom: 0;
				width: 100%;
				height: 0;
			}
			.el-table .btn {
				padding: 5px 10px;
				text-align: center;
				font-size: 12px;
			}
		}
		.el-dialog__header {
			border-bottom: 1px solid #eeeeee;
			padding: 10px 20px;
			.el-dialog__title {
				font-size: 16px;
			}
		}
		.el-dialog__body {
			padding: 0 20px 35px 20px;
			overflow-y: auto;
			max-height: 600px;
			.edit-box {
				.input-box {
					margin-top: 35px;
					padding-left: 15px;
					.input-lebal {
						color: #aeb5b8;
						margin-right: 10px;
						span {
							color: #ff7a65;
							margin-right: 3px;
						}
					}
					.el-icon-remove-outline {
						font-size: 1.5625vw;
						color: #d7d9db;
						margin-left: 10px;
					}
				}
				.avatar-uploader .el-upload {
					border: 1px dashed #d9d9d9;
					border-radius: 6px;
					cursor: pointer;
					position: relative;
					overflow: hidden;
				}
				.avatar-uploader .el-upload:hover {
					border-color: #409eff;
				}
				.avatar-uploader-icon {
					font-size: 28px;
					color: #8c939d;
					width: 90px;
					height: 90px;
					line-height: 90px;
					text-align: center;
				}
				.avatar {
					width: 90px;
					height: 90px;
					display: block;
				}
			}
		}
		.el-dialog__footer {
			padding: 15px 20px 15px;
			box-shadow: 0px -5px 10px -5px #eeeeee;
		}
	}
	.selectShow {
		.el-dialog__header {
			border-bottom: 1px solid #eeeeee;
			padding: 10px 20px;
			.el-dialog__title {
				font-size: 16px;
			}
		}
		.el-dialog__body {
			padding: 0;
			.user-list-box {
				.user-list-head {
					padding: 10px 20px;
					border-bottom: 1px solid #eeeeee;
					.user-type-list {
						.user-type-item {
							color: #8d9da6;
							cursor: pointer;
						}
						.active {
							color: #007aff;
							position: relative;
						}
						.active::after {
							position: absolute;
							width: 25px;
							height: 2px;
							background-color: #007aff;
							content: '';
							bottom: -5px;
							left: 0;
						}
						span {
							color: #cccccc;
							margin: 0 8px;
						}
					}
				}
				.user-list-content {
					height: 40vh;
					.left {
						height: 98%;
						overflow-y: auto;
						border-right: 1px solid #eeeeee;
						padding-top: 5px;
						.left-item {
							padding: 0 20px;
							height: 40px;
							line-height: 40px;
							color: #a4a4a4;
							cursor: pointer;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
						.active {
							background-color: #e4f1ff;
							color: #2d8cf0;
							position: relative;
						}
						.active:after {
							position: absolute;
							top: 0;
							bottom: 0;
							right: 0;
							width: 2px;
							content: '';
							background-color: #2d8cf0;
						}
					}
					.right {
						height: 37vh;
						padding: 10px 25px;
						overflow-y: auto;
						.list {
							display: flex;
							flex-wrap: wrap;
							.item {
								cursor: pointer;
								padding: 5px 15px;
								border-radius: 3px;
								background-color: #f3f3f3;
								margin-right: 10px;
								margin-bottom: 10px;
							}
							.active {
								background-color: #e4f1ff;
								color: #2d8cf0;
							}
						}
					}
				}
			}
		}
		.dialog-title {
			padding: 10px 0 5px 0;
		}
		.el-dialog__footer {
			padding: 15px 20px 15px;
			box-shadow: 0px -5px 10px -5px #eeeeee;
		}
	}
</style>
